import Logo from "../assets/images/hgi_logo.png";
import {enrollmentInfo} from '../api/api';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Terms() {
	const [termsConditions, setTermsConditions] = useState(null)
	useEffect(() => {
		TNC()
	},[])

	async function TNC(){
		const[response,err] = await enrollmentInfo();
		if(response){
			setTermsConditions(response.data.data.terms_and_condition)
		}
		if(err){
			console.log(err)
		}
	}

  return (
        <div>
          <div className="navtop border-bottom border-1">
            <div className="container">
              <div className="d-flex pt-2 pb-3">
              <Link to="/"> 
                <img src={Logo} alt="hgi_logo" className="img-fluid" width="500"/>
              </Link>
              </div>
            </div>
          </div>
          <div className="contactus mb-3">
           <div className="container" dangerouslySetInnerHTML={{__html: termsConditions}}>
              {/* {termsConditions&&parseHTML(termsConditions)} */}
            </div>
          </div>
          {/* <Row className="pt-3 footerstyle" style={{position: 'relative', bottom: 0}}>
            <Col span={24}>
              <div className="container ">
                <p className="text-center">
                </p>
              </div>
            </Col>
          </Row> */}
        </div>
  );
}

export default Terms;
