import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Divider } from "antd";
import { PhoneOutlined, DownloadOutlined } from '@ant-design/icons';
import Group from "../assets/images/Group160.png";
import Logo from "../assets/images/hgi_logo.png";
import {enrollmentInfo} from '../api/api';

function Token() {

  let [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    getContact()
  }, []);

  async function getContact(){
		const[response,err] = await enrollmentInfo();
		if(response){
			setContactInfo(response.data.data.contacts)
		}
		if(err){
			console.log(err)
		}
	}

 const openPDF = (Resume) => {
  const pdfWindow = window.open("test");
  const title = Resume;
  const URI = Resume;
  const html = `
    <html>
      <head>
        <title>${title}</title>
      </head>
      <body style="margin:0">
        <embed width="100%" height="100%" src=${Resume} type="application/pdf">
      </body>
    </html>
  `;

  pdfWindow.document.title = "HAri maya";
  pdfWindow.document.write(html);
  pdfWindow.document.close();
  pdfWindow.history.pushState(null, null, URI);
};

  return (
    <div>
      <div>
        <div>
         <div className="navtop border-bottom border-1">
           <div className="container">
             <div className="d-flex pt-2 pb-3">
               <img src={Logo} alt="hgi_logo" className="img-fluid" width="500"/>
             </div>
           </div>
         </div>

         <div className="contactus ">
           <div className="container">
             <div className="row">
               <div className="col-lg-7">
                 <img
                   src={Group}
                   alt="group"
                   className="img-fluid imgstyle"
                 />
               </div>
               <div className="col-lg-5 ">
                 <div className="formstyle">
                     <h1>Welcome to Himalayan Everest Insurance,</h1>
                     {/* <p>
                     This document provides all details of the "Ncell Data Sanga Beema" hospitalization benefits you have subscribed to, and how you can avail them if needed. Stay Safe!
                     </p> */}
                     <Divider />
                    <div className="mb-5 center">
                      <div></div>
                      <a
                        href={process.env.PUBLIC_URL + "/Ncell HIP Claim Form-Nepali.pdf"}
                        target="_blank"
                        rel="noreferrer noopener"
                        // download={"Ncell-HIP-Claim-Form-English.pdf"}
                      >
                       <Button type="primary" shape="round" icon={<DownloadOutlined />}>
                         Claim Form (नेपाली) 
                       </Button>
                      </a>
                      <a
                        href={process.env.PUBLIC_URL + "/Ncell HIP Claim Form-English.pdf"}
                        target="_blank"
                        rel="noreferrer"
                        // download={"Ncell-HIP-Claim-Form-English.pdf"}
                      >
                      <Button className="mt-2" type="primary" shape="round" icon={<DownloadOutlined />}>Claim Form (English)</Button>
                     </a>
                    </div>
                   <div className="terms text-center pt-5 mb-5 border-top">
                     <Link
                       to="/terms-and-conditions"
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-decoration-none"
                       style={{ color: "#3A4065" }}
                     >
                       {" "}
                       <span className="me-lg-5 me-md-5 me-sm-5 me-2" style={{fontSize: '15px', color: '#7e7ee1'}}><strong>Terms & Conditions</strong></span>
                     </Link>
                     <Link
                       to="/faq"
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-decoration-none"
                       style={{ color: "#3A4065" }}
                     >
                       {" "}
                       <span style={{fontSize: '15px', color: '#7e7ee1'}}><strong>FAQ</strong></span>
                     </Link>
                   </div>
                   <div className="contactno pb-4 pt-4 border-top border-1" style={{position: "relative", bottom: 10}}>
                     <p><strong>Contact No.</strong></p>
                     { contactInfo? contactInfo.map((contact, index) => 
                       <div key={index} className="d-flex pb-2 contactno_style">
                         <span className="me-auto text-muted fw-lighter">
                           <PhoneOutlined className="mb-1 me-2" />
                           {contact.name || 'N/A'}
                         </span>
                         <span className="text-muted fw-lighter">
                           {contact.phone_number || '98XXXXXXXX'}
                         </span>
                       </div>
                     ) : ''}
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <Row className="pt-3 footerstyle" style={{position: 'relative', bottom: 0}}>
           <Col span={24}>
             <div className="container ">
               <p className="text-center">
                "Information collected through this form will be
                 retained by HGI to enable provision of insurance. This
                 will not be shared with third parties."
               </p>
             </div>
           </Col>
         </Row>
        </div>
      </div>
    </div>
  );
}

export default Token;
