import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, np } from "./locale";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  whitelist: ["en", "np"],
  resources: {
   en: en,
   np: np,
  },
  fallbackLng: "en",
  saveMissing: true,
  debug: false,
 });

export default i18n;
