import React from "react";
import { Route, Routes } from "react-router-dom";
import Token from '../components/Token';
import FAQ from '../components/FAQ';
import Terms from '../components/TNC';
import Shayatri from '../components/Sahayatri';
import Roaming from '../components/Roaming';
import ClaimForm from '../components/ClaimForm'

function MainRoute() {
  return (
    <Routes>
      <Route path="/" element={<Token/>}/>
      <Route path="/:token" element={<Token/>} exact="true"/>
      <Route path="/claims-form" element={<ClaimForm/>} exact="true" />
      <Route path="/faq" element={<FAQ/>}  exact="true" />
      <Route path="/terms-and-conditions" element={<Terms />}  exact="true" />
      <Route path="/sahayatri" element={<Shayatri />} exact="true" />
      <Route path="/roaming" element={<Roaming />} exact="true" />
    </Routes>
  );
}

export default MainRoute;