// import logo from './logo.svg';
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/index";
import "antd/dist/antd.css";
import './App.css';
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        {/* <ErrorBoundary> */}
        <Routes />
        {/* </ErrorBoundary> */}
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
