import Logo from "../assets/images/hgi_logo.png";
import { useEffect, useState } from "react";
import { Collapse, Row, Col } from 'antd';
import { enrollmentInfo } from "../api/api";

const { Panel } = Collapse;

function FAQ() {
  const [questions, setQuestions] = useState([])
	useEffect(() => {
		getFAQ()
	},[])

	async function getFAQ(){
		const[response,err] = await enrollmentInfo();
		if(response){
			setQuestions(response.data.data.faq)
		}
		if(err){
			console.log(err)
		}
	}
  return (
        <div>
          <div className="navtop border-bottom border-1">
            <div className="container">
              <div className="d-flex pt-2 pb-3">
                <img src={Logo} alt="hgi_logo" className="img-fluid" width="500"/>
              </div>
            </div>
          </div>
          <div className="contactus mb-3">
           <div className="container">
              <h1>FAQ<small>(Frequently Asked Question)</small></h1>
            <Row justify="center">
              <Col xs={24} sm={24} lg={24} md={24} xl={24} xxl={24} >
                <Collapse expandIconPosition="right">
                { questions.length>0? questions.map((question, Key) => 
                  <Panel key={Key} header={question.question}>
                    <p>{question.answer}</p>
                  </Panel>
                ): '' }
                </Collapse>
              </Col>
            </Row>           
          </div>
          </div>
          <Row className="pt-3 footerstyle" style={{position: 'relative', bottom: 0}}>
            <Col span={24}>
              <div className="container ">
                <p className="text-center">
                </p>
              </div>
            </Col>
          </Row>
        </div>
  );
}

export default FAQ;
