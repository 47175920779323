import Logo from "../assets/images/hgi_logo.png";
import {roaming} from '../api/api';
import { useEffect, useState } from "react";
// import { Dropdown, Button, Menu } from 'antd';
// import { ReactComponent as LanguageLogo } from "../assets/images/icons/language.svg";
// import MenuItem from "antd/lib/menu/MenuItem";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Roaming() {
	const [termsConditions, setTermsConditions] = useState(null);
	const { i18n } = useTranslation();

  // const languageMenu = (
  //   <Menu className="profile__dropdown">
  //     <MenuItem key="1" onClick={() => {
  //       i18n.changeLanguage("en")
  //       TNC()
  //     }
  //     }>
  //       <div>English</div>
  //     </MenuItem>
  //     <MenuItem key="2" onClick={() => {
  //       i18n.changeLanguage("np")
  //       TNC()
  //     }
  //     }>
  //       <div>Nepali</div>
  //     </MenuItem>
  //   </Menu>
  // );


	useEffect(() => {
		TNC()
	},[])

	async function TNC(){
		const[response,err] = await roaming('en');
		if(response){
			setTermsConditions(response.data.content)
		}
		if(err){
			console.log(err)
		}
	}

  return (
        <>
          <div className="navtop border-bottom border-1">
            <div className="container">
              <div className="d-flex pt-2 pb-3">
              <Link to="/"> 
                <img src={Logo} alt="hgi_logo" className="img-fluid" width="500"/>
              </Link>
                {/* <Dropdown
                  overlay={languageMenu}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                  overlayClassName="fix-dropdown"
                >
                  <Button className="icon-btn mr__30">
                    <LanguageLogo />
                  </Button>
                </Dropdown> */}
              </div>
            </div>
          </div>
          <div className="contactus mb-3">
           <div className="container" dangerouslySetInnerHTML={{__html: termsConditions}}>
              {/* {termsConditions&&parseHTML(termsConditions)} */}
            </div>
          </div>
          {/* <Row className="pt-3 footerstyle" style={{position: 'relative', bottom: 0}}>
            <Col span={24}>
              <div className="container ">
                <p className="text-center">
                </p>
              </div>
            </Col>
          </Row> */}
        </>
  );
}

export default Roaming;
